import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Lineup, CardData } from './types';
import './App.css';
import { t } from 'i18next';
import CustomTooltip from './toolTip';

const PastLineups: React.FC = () => {
  const [lineups, setLineups] = useState<Lineup[]>([]);
  const [showHistory, setShowHistory] = useState(false); // State to track visibility of lineups
  const walletAddress = localStorage.getItem('walletAddress') || '';
  
  const currentHostname = window.location.href;
  const stagingKey = process.env.REACT_APP_KEY_STAGING || 'staging2';
  const linkUrl = currentHostname.includes(`${stagingKey}`)
    ? `https://${stagingKey}.peaxel.me`
    : 'https://peaxel.me';
  
  const defaultImage = `${linkUrl}/wp-content/uploads/2024/10/ADD-CARD-LINEUP-PEAXEL-EMPTY.png`;

  useEffect(() => {
    const fetchLineups = async () => {
      try {
        const response = await axios.get<Lineup[]>(`${linkUrl}/wp-json/custom/v1/get-past-lineups`, {
          params: { walletAddress }
        });
        setLineups(response.data);
      } catch (error) {
        console.error('Error fetching lineups:', error);
      }
    };

    if (walletAddress) {
      fetchLineups();
    }
  }, [walletAddress]);

  const toggleHistory = () => setShowHistory(!showHistory);

  return (
    <div className="pastLineupsOption">
      <span 
        onClick={toggleHistory} 
        className="pastLineupsToggle"
      >
        {showHistory ? t('HideLineupHistory') : t('ShowLineupHistory')}
      </span>

      {showHistory && (
        <div>
          {lineups.length > 0 ? (
            lineups.map((lineup, index) => (
              <div key={index} className='past-lineup-gameweek'>
                <h4 style={{color:'#93003F', fontWeight:'bold'}}>{lineup.gameweek}</h4>
                <span style={{
                  padding: '10px',
                  marginBottom: '20px',
                  color: '#FAFAFA',
                  fontWeight: '700',
                  fontSize: '110% !important',
                }}>
                  {lineup.total_lineup}&nbsp;{ parseInt(lineup.total_lineup) > 1 ? t('Cards') : t('Card')}&nbsp;|&nbsp;{lineup.total_score}&nbsp;{t('points')}
                </span>

                <div className='past-lineup-cards'>
                  {[1, 2, 3, 4, 5].map((num) => {
                    const card: CardData | null = lineup[`card_${num}` as keyof Lineup]
                      ? JSON.parse(lineup[`card_${num}` as keyof Lineup] as string)
                      : null;

                    return (
                      <div key={num} className='past-lineup-card-12345'>
                        <CustomTooltip content={
                          <div className="gaming_experience_score">
                            <span className="gaming_row">
                              <span className="gaming_experience_score_text">{t('performance')}</span>
                              <span className="gaming_experience_score_value">{card?.card_score}</span>
                            </span>
                            <span className="gaming_row">
                              <span className="gaming_experience_score_text">{t('bonusForce')}</span>
                              <span className="gaming_experience_score_value">{card?.bonus_card_force}</span>
                            </span>
                            <span className="gaming_row">
                              <span className="gaming_experience_score_text">{t('bonusExperience')}</span>
                              <span className="gaming_experience_score_value">{card?.bonus_card_experience}</span>
                            </span>
                            <span className="gaming_row">
                              <span className="gaming_experience_score_text">{t('bonusCards')}</span>
                              <span className="gaming_experience_score_value">{card?.bonus_similar_cards}</span>
                            </span>
                            <span className="gaming_row">
                              <span className="gaming_experience_score_text_final">{t('currentScore')}</span>
                              <span className="gaming_experience_score_value_final">{card?.final_score}</span>
                            </span>
                          </div>
                        }>
                          <button className="buttonPerformance">
                            <img
                              src={card && card.image_url ? card.image_url : defaultImage}
                              alt={`Card ${num}`}
                            />
                          </button>
                        </CustomTooltip>
                      </div>
                    );
                  })}
                </div>
              </div>
            ))
          ) : (
            <p>No past lineups found.</p>
          )}
        </div>
      )}
    </div>
  );
};

export default PastLineups;
